// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

.alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: auto;
}

.aligncenter {
    display: block;
    margin: 0 auto;
    height: auto;
}

.alignleft,
.alignright {
    margin-bottom: 1rem;
    height: auto;
}

@include mq(small) {
    // Only float if not on an extra small device
    .alignleft {
        float: left;
        margin-right: 1rem;
    }
    .alignright {
        float: right;
        margin-left: 1rem;
    }
}
