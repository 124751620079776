// Page Content
// ------------------

#wrapper {
    position: relative;
    overflow: hidden;
}

#location-map {
    width: 100%;
    height: 400px;
}

.page-section {
    position: relative;

    &:not(#site) {
        & .container {
            &:only-of-type {
                padding: 48px 0;
            }
            &:not(:only-of-type):first-of-type {
                padding-top: 48px;
            }
            &:not(:only-of-type):last-of-type {
                padding-bottom: 48px;
            }
        }
    }



    // .header-is-fixed & {
    //  padding-top: 120px;
    // }

    &#plan {
        & .container {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            & > * {
                flex: 1 0 100%;
                @include mq('medium') {
                    flex: 1 0 50%;
                }
            }
        }
    }
}

.image-slider {
    position: relative;
    width: 100%;
    &.standard {
        margin-bottom: 24px;

        & .single-slide {
            position: relative;
            max-height: 720px;
            min-height: 320px;
            &::before {
                content: '';
                display: block;
                width: 100%;
                // padding-bottom: 56.25%;
                padding-bottom: 62.5%;
            }
        }
    }
    &.full {
        & .single-slide {
            // max-height: 60vmax;
            // height: 400px;
            // @include mq('small') {
            //     height: 540px;
            // }
            // @include mq('medium') {
            //     height: 720px;
            // }
            max-height: 720px;
            min-height: 400px;
            &::before {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: 56.25%;
            }
        }
    }
    & .single-slide {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    #site & {
        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: (100%/3);
            z-index: 1;
            background-image: linear-gradient(0deg, rgba(39, 39, 39, 0.0), #171616);
        }
    }
}

.section {
    &-heading {
        margin: 0;
        padding: 24px 0;
        text-align: center;
        font-size: 24px;
        letter-spacing: 6.9px;
        text-align: center;
        color: rgba(65, 64, 64, 0.9);
        font-family: $font-family--primary;
        text-transform: uppercase;

        #site & {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            color: rgba($oc-white, 0.9);
            font-size: 44px;
            letter-spacing: 12.6px;
            transition: top 0.25s ease;
        }
        #plan & {
            text-align: left;
            padding-left: 10px;
        }
    }
    &-content {
        text-align: center;
        margin: 0 auto 48px;
        font-family: $font-family--secondary;
        font-size: 14px;
        color: rgba(65, 64, 64, 0.9);

        // &:not(.with-image),
        &:not(.with-image):not(.has-table) {
            @include mq('medium') {
                max-width: 60%;
            }
        }

        &.with-image {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(65, 64, 64, 0.9);

            display: flex;
            flex-flow: row wrap;

            & > * {
                flex: 0 0 100%;
            }

            & > p {
                padding: 0 0 0 10px;
            }

            & strong {
                text-transform: uppercase;
                font-weight: bold;
                display: inline-block;
                margin-bottom: 16px;
            }

            @include mq('small') {
                font-size: 19px;
            }
        }

        & ul {
            @extend %list_reset;
            display: flex;
            flex-flow: column nowrap;
            text-align: left;
            // align-items: center;

            padding: 0 10px 0 32px;
            @include mq('small') {
                padding: 0 0 0 32px;
            }

            & li {
                position: relative;
                line-height: 1.5;
                &::before {
                    content: ' \2022';
                    position: absolute;
                    display: block;
                    left: -15px;
                    @include mq('small') {
                        left: -20px;
                    }
                }
            }
        }
    }
}

.contact-form {
    // display: none!important;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 50;
    color: $oc-white;
    padding: 120px 0 60px;
    background-color: rgba(39, 39, 40, 0.95);
    box-shadow: inset 0 4px 2px 0 rgba(0, 0, 0, 0.21);
    font-size: 13px;
    letter-spacing: 0.7px;
    text-align: center;
    color: $oc-white;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.5s ease-in-out;

    &-logo {
        padding: 72px 0 0;

        & img {
            max-width: 240px;
            max-height: 120px;
            margin: 16px;
        }

        & span {
            display: block;
        }

        @include mq('medium') {
            & span {
                display: inline-block;
            }
            & img {
                margin: 0 16px;
            }
        }
    }

    & .container {
        position: relative;
    }

    // @include mq('large-only') {
    //     & .container {
    //         max-width: 100%;
    //     }
    //     & .sp-form {
    //         & ul {
    //             padding: 0 15% 0 30%;
    //         }
    //     }
    // }

    & input[type="text"],
    & input[type="email"],
    & input[type="phone"],
    & input[type="tel"],
    & input[type="url"] {
        background-color: rgba(53, 53, 53, 0.8);
        border: solid 1px #7e7e7e;
        padding: 8px 10px;
    }

    &.is-visible {
        position: fixed;
        opacity: 1;
        transform: translateY(0);
    }
}

.sp-form {
    position: relative;
    & .gform_body {
        padding: 20px 0 30px;
        @include mq('small') {
            padding: 90px 0 60px;
        }
        @include mq('medium') {
            padding: 60px 0;
        }
    }
    & input[type="submit"].button {
        // position: absolute;
        // right: 50%;
        // bottom: 30px;
        // transform: translateX(50%);
        border: 1px solid white;
        padding: 0px 24px;
        height: 28px;
        line-height: 28px;
        transition: all 0.25s ease;

        &:hover {
            color: $brand-text;
            background-color: $oc-white;
        }

        // @include mq('medium') {
        //     bottom: auto;
        //     right: 0;
        //     top: 50%;
        //     transform: translateY(-50%);
        // }
    }
    & ul {
        @extend %list_reset;
        position: relative;
        display: flex;
        flex-flow: row wrap;
        // padding: 0 5% 0 5%;
        //
        // @include mq('medium') {
        //     padding: 0 12.5% 0 25%;
        // }
        // @include mq('large') {
        //     padding: 0 9% 0 25%;
        // }

        & .gfield:not(.sp-form-radios) {
            flex: 1 0 100%;
            margin: 0 0 16px;

            @include mq('medium') {
                flex: 1 1 33.33%;
                margin: 0 0 16px;
                padding: 0 8px;
            }
            @include mq('upto-small') {
                &.sp-form-interest {
                    margin-top: 180px;
                }
            }
            @include mq('upto-medium') {
                text-align: center;

                &.sp-form-interest {
                    margin-top: 210px;
                }
            }
            &.sp-form-name {
                // flex: 1 1 66.67%;
                & .name_first, & .name_prefix {
                    display: block;
                }
                & .name_prefix {
                    // margin: 0 0 16px;
                }
                @include mq('medium') {
                    & .ginput_container {
                        display: flex;
                        & > span {
                            flex: 1;
                            &:first-of-type {
                                // margin: 0 16px 0 0;
                            }
                        }
                    }
                }
            }
            & input:not([type="radio"]):not([type="submit"]):not([type="checkbox"]):not([type="range"]), select {
                width: 100%;
                color: white;
            }
        }
    }
    & label.gfield_label {
        display: none;
    }
    &-contact {
        text-align: center;
        // @include mq('medium') {
        //     position: absolute;
        //     top: 20%;
        //     left: 0;
        // }
    }
    &-radios {
        // position: absolute;
        // top: 40px;
        // left: 0;
        width: 100%;
        // @include mq('medium') {
        //     width: 25%;
        //     top: 50%;
        //     transform: translateY(-50%);
        // }
        //
        // @include mq('large-only') {
        //     left: 5%;
        // }
        margin-bottom: 32px;

        & input[type="radio"] {
            margin: 0 0 0 10px;
            visibility: hidden;


            &:checked ~ .check {
                border: 1px solid #979797;
                &::before {
                    background-color: #979797;
                }
            }
        }

        & .check {
            display: block;
            position: absolute;
            border: 1px solid #979797;
            border-radius: 100%;
            height: 22px;
            width: 22px;
            top: 50%;
            right: -12px;
            z-index: 5;
            transition: border .25s linear;
            pointer-events: none;
            transform: translateY(-50%);

            @include mq('small') {
                right: 30px;
            }
            @include mq('medium') {
                right: 60px;
            }
            @include mq('large') {
                right: 120px;
            }

            &::before {
                display: block;
                position: absolute;
                content: '';
                border-radius: 100%;
                height: 8px;
                width: 8px;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                margin: auto;
                transition: background-color 0.25s linear;
            }
        }

        & .gfield_radio {
            padding: 0 15%;
            @include mq('medium') {
                padding: 0 10%;
            }
            & li {
                position: relative;
                margin: 5px 0;
                flex: 1 0 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include mq('medium') {
                    justify-content: center;
                }
                & label {
                    width: 100%;
                    & img {
                        max-width: 160px;
                    }
                }
                @include mq('small') {
                    & label {
                        & img {
                            max-width: 240px;
                        }
                    }
                }
            }
        }
    }
    &-interest {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq('medium') {
            justify-content: flex-end;
        }
    }
    &-unit { }
    &-title { }
    &-name {
        & label { display: none!important; }
    }
    &-email { }
    &-phone { }
    &-select {
        display: inline-block;
        width: 100%!important;
        position: relative;
        // padding: 10px 0!important;
        .name_prefix_select & {
            padding: 0px!important;
        }
        & select {
            appearance: none;
            border-radius: 0;
            background-color: rgba(53, 53, 53, 0.8);
            border: solid 1px #7e7e7e;
            padding: 8px 10px!important;
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 45%;
            right: 20px;
            width: 8px;
            height: 8px;
            border: 1px solid $oc-white;
            border-top-color: transparent;
            border-left-color: transparent;
            pointer-events: none;
            transform: translateY(-50%) rotate(45deg)
        }
    }
}

.close-contact {
    cursor: pointer;
    position: absolute;
    right: 50%;
    top: 0;
    z-index: 2;
    padding: 0 24px 0 36px;
    border: 1px solid $oc-white;
    border-radius: 50px;
    line-height: 28px;
    transition: all 0.25s ease;
    transform: translateX(50%);

    @include mq('small') {
        top: 5%;
    }

    & .icon {
        font-size: 28px;
        font-weight: lighter;
        position: absolute;
        left: 14px;
        top: -1px;
    }

    &:hover {
        color: $brand-text;
        background-color: $oc-white;
    }

    @include mq('small') {
        right: 0;
        transform: translateX(0);
    }
}

input, select, textarea {
    &:focus {
        outline-color: $oc-blue-5;
    }
}

.validation_error, .validation_message {
    text-align: center;
    color: #FF4545;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 1.25px;
}

.ie {
    & img[src$=".svg"] {
        width: 100%;
    }
    & .svg-logo {
        & svg {
            height: 50px;
        }
    }
}
