// Footer
// ------------------

.footer {
	position: relative;
	min-height: 120px;
	background-image: url('./assets/img/bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	background-attachment: fixed;
	color: $oc-white;
	text-transform: uppercase;
	font-size: 9px;
	letter-spacing: 1.8px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background-color: rgba(66, 66, 66, 0.75);
	}

	& > .container {
		position: relative;
		z-index: 1;
	}

	& a {
		color: inherit;
	}

	&-top, &-bottom {
		display: flex;
		align-items: center;
		// justify-content: space-between;
		flex-flow: row wrap;
	}

	&-top {
		padding: 30px 0px 15px;
	}

	&-bottom {
		padding: 15px 0 30px;
		line-height: 2.8;

		& > * {
			padding: 0 6px;
			margin: auto;
			@include mq('huge') {
				&:first-child:not(:only-child) {
					padding: 0 6px 0 0;
					margin: 0 auto 0 0;
				}
				&:not(:first-child):not(:only-child):not(:last-child) {
					padding: 0 6px;
					margin: auto;
				}
				&:last-child:not(:only-child) {
					padding: 0 0 0 6px;
					margin: 0 0 0 auto;
				}
			}
		}
	}

	&-logo {
		margin: 0 auto 16px;
		height: 75px;
		& img {
		    max-height: 100%;
		    width: auto;
		}
		flex: 1 0 100%;
		text-align: center;
		@include mq('medium') {
			flex: 0 0 50%;
		}
		@include mq('large') {
			margin: 0 20px 0 0;
			flex: 0 0 auto;
			text-align: left;
		}
		@include mq('huge') {
			margin: 0 30px 0 0;
		}
	}

	&-address {
		// flex: 1;
		font-size: 16px;
		letter-spacing: 5.6px;
		color: $oc-white;
		margin: auto;
		text-align: center;
		flex: 1 0 100%;
		@include mq('medium') {
			flex: 0 0 50%;
		}
		@include mq('large') {
			flex: 0 0 auto;
			margin: 0;
			text-align: left;
		}
}

	&-contact {
		// flex: 1;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		margin-left: auto;

		text-align: center;

		flex: 1 0 100%;
		margin-top: 20px;
		margin-bottom: 20px;


		@include mq('medium') {
			flex: 1;
			// margin-top: 15px;
			// margin-bottom: 10px;
		}

		@include mq('large') {
			flex: 1;
			margin-top: 0px;
			margin-bottom: 0px;
			// justify-content: flex-end;
		}

		& > * {
			flex: 1 0 100%;
			text-align: center;
		}

		&-wrapper {
			display: flex;
			flex-flow: row wrap;
			flex: 1;
			@include mq('large') {
				margin-left: 20px;
			}
		}

		&-logo {
			margin-bottom: 12px;
			& img {
				max-height: 54px;
			}
		}
		&-details {
			margin-left: 10px;
			& a[href^='mailto:'] {
				text-decoration: underline;
			}
		}
		&:not(:first-of-type) {
			& .footer-contact-logo {
				& img {
					max-height: 74px;
				}
			}
		}
	}
	&-navigation {
		@extend %list_reset;
		& li {
			display: inline-block;
		}
	}
}
