// Slick Slider
// ------------------

.slick {
    &-slider {
        margin: 0;
        padding: 0;
        // @extend %container;
        // border-top: 5px solid $brand-primary;
    }
    &-slide {
        // list-style: none;
    }
    &-dots {
        @extend %list_reset;
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);

        & li {
            position: relative;
            display: inline-block;

            &.slick-active {
                & button {
                    &::before {
                        background-color: transparent;
                        border: 2px solid $oc-white;
                    }
                }
            }

            & button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 24px;
                height: 24px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;
                &::before {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 14px;
                    height: 14px;
                    content: " ";
                    text-align: center;
                    opacity: 1;
                    background-color: $oc-white;
                    border-radius: 100%;
                }
            }
        }
    }
}
.hero {
    &-image {

    }
}
