// Global Config
// ------------------

@font-face {
    font-family: 'Cera Basic';
    src: url('./assets/fonts/cerabasic-regular-webfont.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/cerabasic-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/cerabasic-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/cerabasic-regular-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('./assets/fonts/cerabasic-regular-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/cerabasic-regular-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Geometrica';
    src: url('./assets/fonts/geometricasans-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/geometricasans-regular-webfont.woff') format('woff'); /* Pretty Modern Browsers */
}
// Fonts
$stack-serif: Georgia, Times, Times New Roman, serif;
$stack-sans-serif: Arial, "Helvetica Neue", Helvetica, sans-serif;
$stack-system: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

// $font-family--primary: 'Geometrica', $stack-sans-serif;
$font-family--primary: 'Montserrat', $stack-sans-serif;
// $font-family--primary: 'proxima-nova', $stack-sans-serif;
$font-family--secondary: 'proxima-nova', $stack-sans-serif;
// $font-family--secondary: 'Cera Basic', $stack-sans-serif;
$font-family--tertiary: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, $stack-sans-serif;

$base-font-size: 16px;
$base-line-height: 1.4;

// Colours
// Uncomment as applicable
$brand-primary: $oc-blue-5;
$brand-secondary: #484646;
$brand-tertiary: $oc-blue-5;
$brand-text: rgb(64,64,64);
$brand-text-alt: #949494;
$site-background-color: $oc-white;
$footer-background-color: $oc-white;
$header-background-color: $brand-secondary;
$link-color: $brand-secondary;
$link-color-hover: lighten($brand-secondary, 5%);
$nav-child-highlight-background: $brand-primary;
$nav-child-highlight-text: $brand-secondary;
$nav-child-text: $oc-white;
$nav-child-background: $brand-secondary;

// Breakpoints
$breakpoint: (
    "mobile": 400px,
    "small": 600px,
    "medium": 768px,
    "large": 1024px,
    "huge": 1200px
);
$bp-mob: map-get($breakpoint, "mobile");
$bp-sml: map-get($breakpoint, "small");
$bp-med: map-get($breakpoint, "medium");
$bp-lrg: map-get($breakpoint, "large");
$bp-huge: map-get($breakpoint, "huge");

/* How to use Media Queries
mobile-only, small, small-only, medium, medium-only, large, large-only, huge
@include mq(size needed, [optional target: screen etc]) { styles here }
*/
