// Navigation
// ------------------

.logo {
    width: 120px;
    display: inline-block;
    & a {
        display: block;
    }
    @include mq('small') {
        width: 160px;
    }
}

.header {
    // position: fixed;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;

    &-inner {
        position: relative;
        z-index: 1;
        background-color: rgba($header-background-color, 0.95);
        padding: 32px 0;
        // padding: 40px 0;
        // padding: 48px 0;
    }

    &.is-fixed {
        position: fixed;
    }

    & .header-inner .container {
        display: flex;
        align-items: center;

        & .navigation {
            margin-left: auto;
        }
    }
}

.navigation {
    display: none;
    @include mq('large') {
        display: block;
    }
}

.main-nav,
.landing-nav {
    @extend %list_reset;
    display: flex;
    justify-content: flex-end;

    & li {
        & a {
            display: block;
            font-size: 14px;
            padding: 8px;
            letter-spacing: 2.2px;
            color: $oc-white;
            @include mq ('huge') {
                letter-spacing: 4.7px;
            }
        }
        @include mq('medium') {
            &:first-of-type {
                & a {
                    padding: 8px 10px 8px 0;
                }
            }
            &:last-of-type {
                & a {
                    padding: 8px 0 8px 10px;
                }
            }
            & a {
                padding: 8px 10px;
            }
        }
        @include mq('large') {
            &:first-of-type {
                & a {
                    padding: 10px 12px 10px 0;
                }
            }
            &:last-of-type {
                & a {
                    padding: 10px 0 10px 12px;
                }
            }
            & a {
                padding: 10px 12px;
            }
        }
    }
}
.landing-nav {
    margin-top: 80px;
    justify-content: center;
    flex-flow: row wrap;
    & li {
        width: 100%;
        @include mq('medium'){
            width: 50%;
        };
        @include mq('large'){
            width: auto;
        };
        & a {
            font-size: 15px;
        }
    }
}

.navigation {
    &-top {
        font-size: 12px;
        letter-spacing: 2.4px;
        text-align: right;
        color: #bdbdbd;
        text-transform: uppercase;

        & span {
            color: $oc-white;
            margin-left: 6px;
        }
    }
    &-bottom {
        display: flex;
        align-items: center;

        & .get-in-touch {
            height: 28px;
            margin-left: 24px;
        }
    }
}

.mobile {
    &-nav {
        position: absolute;
        top: 0;
        z-index: 0;
        width: 100%;
        padding: 1rem 0;
        color: $oc-white;
        background-color: rgba($header-background-color, 0.95);
        transform: translateY(-100%);
        transition: transform 0.5s ease-in-out;
        text-align: center;

        & a {
            display: block;
            color: $oc-white;
            padding: 10px 0;

            &.get-in-touch {
                margin: 24px 0 12px 0;
                display: inline-block;
            }
        }
        &.is-active {
            top: 100%;
            transform: translateY(0%);
        }
        &-trigger {
            margin-left: auto;
            // width: 24px;
            // height: 20px;
            // display: flex;
            // flex-flow: column nowrap;
            // justify-content: space-between;
            //
            @include mq('large') {
                display: none;
            }
            //
            // & span {
            // 	display: block;
            // 	width: 100%;
            // 	height: 2px;
            // 	background-color: white;
            // }
        }
        & ul {
            @extend %list_reset;
            width: 100%;
        }
    }
}
