// Tables
// ------------------
.table-container {
    overflow-x: scroll;
    width: 77.5vw;
    &.has-overflow {
        box-shadow: inset -16px 0px 12px -12px rgba(0,0,0,0.32);
    }
    & table {
        min-width: 480px;
    }
    @include mq(medium) {
        width: auto;
    };
}
table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    font-size: 14px;

    & thead {
        display: none;
        @include mq('medium') {
            display: table-row;
            display: table-row-group;
        }
    }

    & tr {
        display: block;
        margin-bottom: 16px;
        @include mq('medium') {
            display: table-row;
        }
    }

    & td, & th {
        font-family: $font-family--secondary;
        letter-spacing: 0.5px;
        padding: 12px;
        // border-bottom: 1px solid transparentize($brand-text-alt, 0.4);
        border-bottom: 1px solid transparentize($brand-text-alt, 0);
        @include mq('medium') {
            padding: 16px;
        }
    }

    & th {
        white-space: nowrap;
    }

    & td {
        &:first-of-type,
        &:last-of-type {
            text-transform: uppercase;
        }
        &:last-of-type {
            font-weight: bold;
            font-size: 12px;
        }

        position: relative;
        display: block;
        padding: 12px 12px 12px 96px;
        text-align: left;

        &::before {
            content: attr(data-label);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            text-transform: uppercase;
            font-weight: bold;
            white-space: nowrap;
            display: block;
            font-size: 12px;
        }

        @include mq('medium') {
            display: table-cell;
            text-align: center;
            padding: 16px;
            &::before {
                content: '';
            }
        }
    }

    & thead, & tfoot {
        & td, & th {
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}