// Mobile Trigger
// ------------------

$trigger-size: 24px;

.menu-trigger {
    width: $trigger-size;
    height: ($trigger-size / 1.25);
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 2501;

    display: flex;

    @include mq('large') {
        display: none;
    }

    & .menu-trigger-inner {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    & span {
        display: block;
        // position: absolute;
        height: 2px;
        width: 100%;
        background-color: $oc-white;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            transform-origin: left center;
        }
        &:nth-child(2) {
            transform-origin: left center;
        }
        &:nth-child(3) {
            transform-origin: left center;
        }
    }
    &.is-open {
        & span {
            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}
