// Layout
// ------------------

%container {
    max-width: 90%;
    margin: 0 auto;
    @include mq('small') {
        max-width: 85%;
    };
    @include mq('huge') {
        max-width: 1160px;
    };
}
.container {
    @extend %container;
}
.site-content {
    @extend %container;
}

[flex-row] {
    display: flex;
    flex-flow: row wrap;
    &[center] {
        justify-content: center;
    }
    &[center-right] {
        align-items: center;
        justify-content: flex-end;
    }
}
[flex-column] {
    display: flex;
    flex-flow: column wrap;
    &[center] {
        align-items: center;
    }
}

[flex] {
    flex: auto;

    &[flex='1'] {
        flex: 1 1 0%;
    }
}

%list_reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

.arrow {
    cursor: pointer;
    margin: 48px auto;
    position: relative;
    display: block;
    width: 16px;
    height: 16px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        border: 2px solid $brand-text-alt;
        width: 100%;
        height: 100%;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        transform: rotate(45deg);
        border-radius: 2px;
    }
}
