// Landing (page)
// ------------------

.landing-section {
	position: relative;
	z-index: 100;
	min-height: 100vh;
	color: white;
    text-align: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;

	.ie & {
		display: flex;
		justify-content: center;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to bottom, rgba(39, 39, 39, 0.0), #171616);
	}

	& .content {
		position: relative;
	    display: flex;
	    flex-direction: column;
	    min-height: 100vh;
	    align-items: center;
	    justify-content: space-between;
	}

	& .container {
	    flex-grow: 1;
	    display: flex;
		flex-flow: column nowrap;
		justify-content: space-around;
	}

	& .get-in-touch {
	    margin-top: 3rem;
	    font-size: 10px;

	    & a {
			display: inline-block;
			padding: 0.5em 1em 0.5em 3em;
			border: 1px solid $oc-white;
			color: $oc-white;
			border-radius: 22px;
			text-decoration: none;

			&::before {
				content: '';
				height: 1em;
				width: 1em;
				display: block;
				position: absolute;
				left: 1em;
				top: 0.5em;
				background: url("../images/envelope.svg") center bottom / contain no-repeat;
			}
		}
	}

	& .dev-by {
		padding: 24px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $font-family--primary;
		font-size: 10px;
		letter-spacing: 6px;
		text-align: center;
		color: $oc-white;
	    flex-flow: row wrap;

		& img {
			margin: 0 0 0 20px;
			max-width: 180px;
		}
	}

	& .addr {
		font-size: 15px;
		letter-spacing: 11px;
		text-align: center;
		text-transform: uppercase;
		margin: 2rem 0 5rem;
	}

	& .desc {
		& h3 {
			color: $oc-white;
			font-size: 35px;
			font-weight: 300;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			margin: 3rem 0 0;
			& strong {
				font-weight: 600;
			}
		}
		& h4 {
			color: $oc-white;
			font-size: 20px;
		}
		& p {
			font-size: 16px;
			font-family: $font-family--secondary;
		}
	}

	& footer {
		border-top: solid 2px rgba(128, 130, 133, 0.36);
		font-size: 8px;
		letter-spacing: 2px;
		text-align: left;
		justify-content: center;
		width: 100%;
		margin-top: 2.5rem;

		@media (min-width: 600px) {
			display: flex;
			& .contact {
				display: flex;
			}
		}

		& .contact {
			justify-content: center;
			align-items: center;
			margin: 1.25rem;
		}

		& .goold-logo {
			width: 156px;
			height: 44px;
			margin-right: 1rem;
		}

		& .colliers-logo {
			width: 54px;
			height: 53px;
			margin-right: 1rem;
		}

		& a {
			color: white;
			text-decoration: none;
		}
	}
}

.feature-video {
	&-wrapper {
		@extend .container;
		padding: 0!important;
		margin-top: 24px;
		margin-bottom: 48px;
		@include mq('upto-small') {
			margin-left: 0;
		    margin-right: 0;
		    max-width: 100%;
		    width: 100%!important;
		};
	}

	position: relative;
	width: 100%;
    margin: auto;

	@include mq('medium') {
		width: 80%;
	};

	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&::before {
		content: '';
		display: block;
		height: 0;
		padding-top: 56.25%;
	}
}


@media (max-width: 560px) {
    .container {
        width: 90%;
    }
}
