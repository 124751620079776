// Base Styles
// ------------------

*, *::after, *::before {
    box-sizing: border-box;
}

html {
    font-family: $font-family--primary;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(black, 0);
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
    @if variable-exists(brand-text) {
        color: $brand-text;
    } @else {
        color: $oc-gray-8;
    }
    @if variable-exists(site-background-color) {
        background-color: $site-background-color;
    } @else {
        background-color: $oc-white;
    }
    font-size: $base-font-size;
    font-weight: 300;
    line-height: $base-line-height;
    letter-spacing: 1px;

    &.menu-is-open,
    &.contact-is-visible {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

img {
    max-width: 100%;
    border: 0;
    vertical-align: middle;
    height: auto;
}

.screen-reader-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    user-select: none;
}

h1, h2, h3, h4, h5, h6 {
    @if variable-exists(brand-text-alt) {
        color: $brand-text-alt;
        font-weight: 400;
    }
    font-family: $font-family--secondary;
}

h2 {
    font-size: 2.2rem;
}

p {
    &:not(:last-of-type),
    &:not(:only-of-type) {
        margin: 0 0 16px;
    }
    &:only-of-type {
        margin: 0;
    }
}

a {
    cursor: pointer;
    @if variable-exists(link-color) {
        color: $link-color;
    } @else {
        color: inherit;
    }
    text-decoration: none;
    outline: none;

    &:hover {
        @if variable-exists(link-color-hover) {
            color: $link-color-hover;
        } @else {
            color: inherit;
        }
    }
}

svg {
    max-width: 90%;
    @include mq('small') {
        max-width: 100%;
    }
}

.btn {
    cursor: pointer;
    position: relative;
    color: $oc-white;
    background-color: transparent;
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid;
    border-color: transparent;
    z-index: 0;
    text-decoration: none;
    font-family: $font-family--primary;
    line-height: 26px;
    appearance: none;
    border-radius: 32px;

    &-outline {
        border-color: #dcd9d9;

        &.dark {
            color: #5a5959;
            border-color: #5b5959;
        }
    }

    &-fill {
        color: $brand-primary;
        background-color: $oc-white;
    }

    font-size: 12px;

    &.get-in-touch, &.download {
        text-align: center;
        font-family: $font-family--tertiary;
        padding: 0px 16px 0px 40px;
        transition: all 0.25s ease-in-out;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            background-size: 14px 28px;
            background-position: 0 0px;
        }

        &.dark {
            &::before {
                background-position: 0 -14px;
            }
            .contact-is-visible &,
            &:hover {
                color: $oc-white;
                background-color: $brand-text;
                &::before {
                    background-position: 0 0px;
                }
            }
        }

        .contact-is-visible &,
        &:hover {
            color: #5a5959;
            background-color: $oc-white;
            &::before {
                background-position: 0 -14px;
            }
        }
    }
    &.get-in-touch {
        &::before {
            background-image: url('./assets/img/mail_sprite.svg');
        }
    }
    &.download {
        &::before {
            background-image: url('./assets/img/download_sprite.svg');
        }
    }
}

button, input[type="button"], input[type="submit"] {
    @extend .btn;
}


#bottom-contact {
    text-align: center;

    & .text {
        padding: 0 0 32px;
    }
}
