// Utilities
// ------------------

@mixin mq($canvas, $target: 'screen') {
    @if $canvas == 'mobile-only' {
        @media #{$target} and (max-width: $bp-mob - 1px) { @content; }
    }
    @else if $canvas == 'upto-small' {
        @media #{$target} and (max-width: $bp-sml - 1px) { @content; }
    }
    @else if $canvas == 'small' {
        @media #{$target} and (min-width: $bp-sml) { @content; }
    }
    @else if $canvas == 'small-only' {
        @media #{$target} and (min-width: $bp-sml) and (max-width: $bp-med) { @content; }
    }
    @else if $canvas == 'upto-medium' {
        @media #{$target} and (max-width: $bp-med - 1px) { @content; }
    }
    @else if $canvas == 'medium' {
        @media #{$target} and (min-width: $bp-med) { @content; }
    }
    @else if $canvas == 'medium-only' {
        @media #{$target} and (min-width: $bp-med) and (max-width: $bp-lrg) { @content; }
    }
    @else if $canvas == 'upto-large' {
        @media #{$target} and (max-width: $bp-lrg - 1px) { @content; }
    }
    @else if $canvas == 'large' {
        @media #{$target} and (min-width: $bp-lrg) { @content; }
    }
    @else if $canvas == 'large-only' {
        @media #{$target} and (min-width: $bp-lrg) and (max-width: $bp-huge) { @content; }
    }
    @else if $canvas == 'upto-huge' {
        @media #{$target} and (max-width: $bp-huge - 1px) { @content; }
    }
    @else if $canvas == 'huge' {
        @media #{$target} and (min-width: $bp-huge) { @content; }
    }
    @else if $canvas == 'portrait' {
        @media #{$target} and (orientation:portrait) { @content; }
    }
    @else if $canvas == 'landscape' {
        @media #{$target} and (orientation:landscape) { @content; }
    }
}

@mixin font($size: $base-font-size, $family:'', $lineheight: '1.7', $case:'') {
    font-size: $size;
    line-height: ($size * $lineheight);
    font-family: $family;
    @if #{$case} {
        text-transform: #{$case};
    }
}

@mixin stripes($dir: 'left', $size: 50px, $bckgrd: $site-background-color, $stripe_colour: $oc-white) {
    background-size: $size $size;
    background-color: $bckgrd;
    @if $dir == 'left' {
        background-image: linear-gradient(to top left, $stripe_colour 25%, transparent 25%, transparent 50%, $stripe_colour 50%, $stripe_colour 75%, transparent 75%, transparent);
    }
    @if $dir == 'right' {
        background-image: linear-gradient(to top right, $stripe_colour 25%, transparent 25%, transparent 50%, $stripe_colour 50%, $stripe_colour 75%, transparent 75%, transparent);
    }
}