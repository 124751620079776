/*!
 Theme Name:   Steel Park
 Theme URI:    http://www.barques.co.uk/
 Description:  Goold Estates Steel Park
 Author:       Axl Crawford
 Author URI:   https://www.barques.co.uk/
 Version:      0.0.1
*/

// Third Party
@import "vendor/open-color";
@import "../../node_modules/normalize.css/normalize";

// Variables & Set-up
@import "config";
@import "utilities";

// Custom Styles
@import "base";
@import "modules/layout";
@import "modules/nav";
@import "modules/mobile-trigger";
@import "modules/slider";
@import "modules/footer";
@import "modules/content";
@import "modules/wp-classes";
@import "modules/tables";
@import "partials/landing";
